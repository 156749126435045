.reach-toggler {
	position: relative;

	display: inline-block;

	color: var(--color-text-400);

	--__space_px: 1;
	--__space: calc((var(--__space_px) / 8) * 1em);

	--__indicator-size: calc(1em - (var(--__space)));

	border-radius: calc((0.5 * var(--__indicator-size)) + var(--__space));
	height: calc(var(--__indicator-size) + (2 * var(--__space)));
	width: calc((2 * var(--__indicator-size)) + (3 * var(--__space)));

	background-color: currentColor;

	&::after {
		content: '';
		position: absolute;
		top: var(--__space);
		left: var(--__space);
		aspect-ratio: 1;
		height: var(--__indicator-size);
		border-radius: calc((var(--__indicator-size) + (2 * var(--__space))) / 2);
		background-color: var(--color-bg-190);
		transform: translateX(0px);
		transition: transform 300ms;
	}

	&.active {
		color: var(--color-primary);

		&::after {
			transform: translateX(calc(var(--__indicator-size) + var(--__space)));
		}
	}
}
